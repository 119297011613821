$( document ).ready(function() {
	// check touch support
		if( 'ontouchstart' in window ){ var click = 'touchstart'; }
			else { var click = 'click'; }

	//Header constructor
	//            Advans Block select
	//=================================================
	(function(){
		$('#top-panel-sel').change(function() {

			var $this = $(this),
					root = './assets/img/',
					img = $this.find('option:selected').data('src'),
					srcImg = $('.header-demo__image_top');
			
			srcImg.attr('src', root+img);
		});
	})();

	(function(){
		$('#middle-panel-sel').change(function() {

			var $this = $(this),
					root = './assets/img/',
					img = $this.find('option:selected').data('src'),
					srcImg = $('.header-demo__image_middle');
			
			srcImg.attr('src', root+img);
		});
	})();

	(function(){
		$('#menu-sel').change(function() {

			var $this = $(this),
					root = './assets/img/',
					img = $this.find('option:selected').data('src'),
					srcImg = $('.header-demo__image_menu');
			
			srcImg.attr('src', root+img);
		});
	})();

	//            Features
	//=================================================

	(function(){
		if (window.matchMedia('(min-width: 1280px)').matches) {
			$('.feat__descr').equalHeights();
		}
		
	})();

	//            Masonry
	//=================================================

	(function(){
		if (window.matchMedia('(max-width: 1280px)').matches) {
			$('.r-blocks__grid-wrapper').packery({
					itemSelector: '.r-blocks__grid-i',
					gutter: 30
				});
		}

		if (window.matchMedia('(min-width: 768px)').matches) {
			$('.r-blocks__grid-wrapper').packery({
				itemSelector: '.r-blocks__grid-i',
				gutter: 30
			});
		}

	})();

	//Mobile hamburger menu

	(function(){
		$('.hamburger').on('click', function(e){
			e.preventDefault();

			$(this).toggleClass('opened');
			$('.page-menu').toggleClass('opened');
			$('body').toggleClass('freeze');

			return false;
		});
	})();

	// window.onload = function () {

	// $('.hamburger').on('click', function(){
	// 		$(this).toggleClass('opened');
	// 		$('.page-menu').toggleClass('opened');
	// 		$('body').toggleClass('freeze');
	// 	});

	// };

	//Scroll windows 
	(function(){
		$('.welcome__scroll').on('click',function(e){
			e.preventDefault();
			var h = $(window).height();
			
			var scrollT = $('body,html').scrollTop();
			$('body,html').animate({scrollTop: h+'px'}, 600);
		});
	})();

	//Scrolling top

	(function(){
		$('.page-footer__to-top').on('click', function(e){
			e.preventDefault();

			 $("html, body").animate({ scrollTop: 0 }, 1500);
		});
	})();

	//Hub tablet and mobile user menu toggle 

	(function(){
		$('.panel__user').on('click', function(e){
			e.preventDefault();

			$('.panel__user-submenu').toggleClass('is-open');

			$(document).bind(click, function(e) {
				if($(e.target).closest('.panel__user').length == 0) {
					$('.panel__user-submenu').removeClass('is-open'); // любая реализация скрытия
					$(document).unbind(click); // снимаем бинд, чтобы не 
				}
			});
			$('.user-menu__item-link').on('click', function(e){
				e.stopPropagation();
			});
		});
	})();

	//Indenting Post page comment replies
	(function(){
		if (window.matchMedia('(min-width: 768px)').matches) {
			$('.comment-outer > .comment-outer').each(function(){
				// 
				var $this = $(this),
						width = $this.innerWidth();

				if( width < 410 ) {

					$this.addClass('no-indented');
				}
				
			});
		}
	})();

	//Stylized form elements
	// Styling checkboxes and radio
	!function(t,s){function i(s,n){var e=this;e.$e=t(s),e.options=t.extend({},i.default,n),e.init()}i.default={stylize_class:"form"},i.prototype.init=function(){var s=this;if(s.$e.hasClass(s.options.stylize_class+"-uni-input"))return!1;s.$e.addClass(s.options.stylize_class+"-uni-input"),s.$p=s.$e.parent(),s.$p.is("label")?s.$p.addClass(s.options.stylize_class+"-label"):(s.$p=t('<label class="'+s.options.stylize_class+'-label"></label>'),s.$e.wrap(s.$p)),s.$e.after('<span class="'+s.options.stylize_class+'-stylized-option"></span>')},t.fn.stylizeInput=function(s){return this.each(function(){t(this).data("stylizeInput")||t(this).data("stylizeInput",new i(this,s))})},t.fn.stylizeInput.Constructor=i}(jQuery);
	(function(){
		$('input[type=checkbox],input[type=radio]').stylizeInput();
	})();

	//File upload trigger
	(function(){
		$(".support__uploader").on('click', function(){
					
			$(".support__file-upload:hidden").trigger('click');
			
		});

		$('.support__file-upload').on('change', function() {
			
			var val = $(this).val().replace(/C:\\fakepath\\/i, '')
			$('.support__upload-text').text(val);
			
		})
	})();

});























